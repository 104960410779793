import axios from '../../api/axios'
import { apiUrl } from '@/config/config'
import store from '../../store'

axios.defaults.baseURL = apiUrl || 'http://192.168.66.41:9996/' || apiUrl
//axios.defaults.baseURL = 'http://192.168.66.42:9995/' //云上课堂本地

axios.defaults.timeout = 20000

function http (method, url, params, config) {
  if (typeof config === 'undefined') config = {}
  return new Promise((resolve) => {
    if (typeof params === 'undefined') params = {}
    if (typeof params.eduId === 'undefined') params.eduId = store.state.user.userInfo.organizationIdStr
    // params.eduId = '39fa5519dbd84e367b3792d9b634a579'
    if (method.toLowerCase() === 'get') params = { params }

    axios[method.toLowerCase()](url, params, config).then((res) => {
      return resolve(res)
    }).catch(function () {
      return resolve({ data: { code: 500, message: '请求错误' } })
    })
  })
}

export default {

  get: function (url, params, config) {
    return http('GET', url, params, config)
  },

  post: function (url, params, config) {
    return http('POST', url, params, config)
  },

  create: function (config) {
    return axios.create(config)
  }
}

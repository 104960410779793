export default  [
  {
    path: '/county',
    name: 'county',
    component: () => import('@/themes/county/index'),
    meta:{
      title:'区县指挥中心',
      pushPage: true
    }
  },
  {
    path: '/start',
    name: 'start',
    component: () => import('@/themes/county/start'),
    meta:{
      title:'区县指挥中心',
      pushPage: true
    }
  }
]
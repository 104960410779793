export default [
  {
    path: '/video-conferencing',
    name: 'video-conferencing',
    component: () => import('@/themes/videoConferencing/index'),
    meta: {
      title: '视频会议',
      pushPage: true
    }
  },
  {
    path: '/video-conferencing/multiplayer',
    name: 'video-conferencing-multiplayer',
    component: () => import('@/themes/videoConferencing/multiplayer'),
    meta: {
      title: '视频会议',
      pushPage: true
    }
  },
  {
    path: '/video-conferencing/single',
    name: 'video-conferencing-single',
    component: () => import('@/themes/videoConferencing/single'),
    meta: {
      title: '视频会议',
      pushPage: true
    }
  }
]

const filtersIndex = {
    install: function (Vue, options) {
        Vue.filter('capitalize', function (value) {
            if (!value) return
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        })
        Vue.filter('timestampZh',function(value){
            if(!value){
                return
            }
            value = value * 1000
            var date = new Date(value);   
            var Y = date.getFullYear();
            var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1);
            var D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
            var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
            var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
            var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
            if(value < 1000){
                return value+'毫秒'
            }else if(value >= 1000 && value < 60000){
                return s+'秒'
            }
            else if(value >= 60000 && value < 3600000){
                return m+'分钟'+s+'秒'
            }
            else if(value >= 3600000 && value < 86400000){
                return h+'小时'+m+'分钟'+s+'秒'
            }else if(value >= 86400000 && value < 86400000*24){
                return D+'天'+h+'小时'+m+'分钟'+s+'秒'
            }else{
                return Y+'年'+M+'月'+D+'日'+h+'时'+m+'分'+s+'秒';
            }
        })
        Vue.filter('formatDateTime', function (value, formatStr) {
            var self = ''
            if (value) {
                if(typeof value == 'string'){
                    value = value.replace(/T/g, ' '); //去掉T
                    value = value.replace(/-/g, '/');
                    value = value.replace(/\.\d+/, ' ');//去掉毫秒
                    self = new Date(value)
                } else if (typeof value == 'number') {
                    self = new Date(value)
                } else{
                    self = value
                }
                
            }else{
                self = new Date()
                
            }
            
            var str = formatStr
            var Week = ['日', '一', '二', '三', '四', '五', '六']
            str = str.replace(/yyyy|YYYY/, self.getFullYear())
            str = str.replace(/yy|YY/, (self.getYear() % 100) > 9 ? (self.getYear() % 100).toString() : '0' + (self.getYear() % 100))
            str = str.replace(/MM/, self.getMonth() > 8 ? (self.getMonth() + 1).toString() : '0' + (self.getMonth() + 1))
            str = str.replace(/M/g, self.getMonth())
            str = str.replace(/w|W/g, Week[self.getDay()])
            str = str.replace(/dd|DD/, self.getDate() > 9 ? self.getDate().toString() : '0' + self.getDate())
            str = str.replace(/d|D/g, self.getDate())
            str = str.replace(/hh|HH/, self.getHours() > 9 ? self.getHours().toString() : '0' + self.getHours())
            str = str.replace(/h|H/g, self.getHours())
            str = str.replace(/mm/, self.getMinutes() > 9 ? self.getMinutes().toString() : '0' + self.getMinutes())
            str = str.replace(/m/g, self.getMinutes())
            str = str.replace(/ss|SS/, self.getSeconds() > 9 ? self.getSeconds().toString() : '0' + self.getSeconds())
            str = str.replace(/s|S/g, self.getSeconds())
            return str
          })
    }
}
export default filtersIndex

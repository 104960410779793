import {getBaseSet, getTemplate, updateHomepageSettings} from '@/api/indexSetApi'
const indexSet = {
    namespaced: true,
    state: {
      
    },
    getters: {
        
    },
    mutations: {
      
    },
    actions: {
        GetBaseSet ({state, commit, dispatch, rootState}, params) {
          return new Promise((resolve, reject) => {
            getBaseSet(params).then((res) => {
                  resolve(res)
              })
          })
        },
        GetTemplate ({state, commit, dispatch, rootState}, params) {
            return new Promise((resolve, reject) => {
                getTemplate(params).then((res) => {
                    resolve(res)
                })
            })
        },
        UpdateHomepageSettings ({state, commit, dispatch, rootState}, params) {
            return new Promise((resolve, reject) => {
                updateHomepageSettings(params).then((res) => {
                    resolve(res)
                })
            })
        }
    }
}
export default indexSet

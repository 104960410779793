export default [
  {
    path: '/monitor-center',
    name: 'monitor-center',
    component: () => import('@/themes/monitorCenter/index'),
    meta: {
      title: '监控中心',
      pushPage: true
    }
  },
]

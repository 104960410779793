import axios from './axios'
import qs from 'qs'
import {apiUrl} from '@/config/config'
// 课堂考勤数据  /district-command-center/dataStatistics/studentCheckData
export const getData = (data) => {
    return new Promise((resolve,reject) => {
        axios({
            url: apiUrl + '/district-command-center/dataStatistics/studentCheckData',
            method: 'get',
            params: data
        }).then(res => {
            resolve(res.data)
        })
    })
}
// 课堂考勤 今日数据  /district-command-center/dataStatistics/studentCheckDataDetail/{eduId}
export const getTodayData = (data) => {
    return new Promise((resolve,reject) => {
        axios({
            url: apiUrl + '/district-command-center/dataStatistics/studentCheckDataDetail',
            method: 'get',
            params: data
        }).then(res => {
            resolve(res.data)
        })
    })
}
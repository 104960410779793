import axios from './axios'
import qs from 'qs'
import {oauthUrl} from '@/config/config'
 
export const refreshToken = (data) => {
  return new Promise((resolve, reject) => {
      axios({
          url: oauthUrl + '/api/auth/login/refresh-token',
          method: 'post',
          // withCredentials: true,
          data
      }).then(res => {
          resolve(res)
      })
  })
}

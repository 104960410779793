<template>
  <div id="app" style="">
    <router-view/>
    <!-- <el-radio v-model="radio" label="1">备选项</el-radio>
    <el-radio v-model="radio" label="2">备选项</el-radio> -->
  </div>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {
      radio: '1'
    }
  },
  watch: {
    '$route' (to, from) {
      // this.handleLoading(2000)
    }
  },
  mounted () {
  //   document.documentElement.style.fontSize = '100px'
  //   var body = document.body
    
  //    var width = 1920
  //    var height = 1052
  //   function resize() {
  //       var wWidth =  window.innerWidth
  //      var pro = wWidth / width
      

  //      var left = width*(1-pro) / 2
  //      var top = height*(1-pro) / 2
    
  //     body.style.transform = `scale(${pro})`
  //      body.style.marginLeft = "-"+left+"px"
  //       body.style.marginTop = "-"+top+"px"


  //       console.log(body.offsetLeft)
  //   }
    
  //  resize()
   
  //   window.onresize = resize
   
  }
}
</script>

<style src='@/assets/css/animate.css'>
</style>
<style>
html{background: #000;overflow-x: hidden; height: 100%;}
body{width: 100%; height: 100%;}
#app{
  height: 100%;
}
::-webkit-input-placeholder { /* WebKit browsers */
    color:#999;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color:#999;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    color:#999;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
    color:#999;
}
::-webkit-scrollbar-track-piece {
    background-color: transparent;
}
::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: transparent;
}
::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: hsla(220, 4%, 58%, .3);
}
::-ms-scrollbar-track-piece {
    background-color: transparent;
}
::-ms-scrollbar {
    width: 7px;
    height: 7px;
    background-color: transparent;
}
::-ms-scrollbar-thumb {
    border-radius: 5px;
    background-color: hsla(220, 4%, 58%, .3);
}


.el-message {
  top: 40% !important;
}
</style>

import {getData, getTodayData} from '@/api/classAttendApi'
const classAttend = {
    namespaced: true,
    state: {
      
    },
    getters: {
        
    },
    mutations: {
      
    },
    actions: {
      GetData ({state, commit, dispatch, rootState}, params) {
          return new Promise((resolve, reject) => {
            getData(params).then((res) => {
                  resolve(res)
              })
          })
      },
      GetTodayData ({state, commit, dispatch, rootState}, params) {
        return new Promise((resolve, reject) => {
          getTodayData(params).then((res) => {
                resolve(res)
            })
        })
      }
    }
}
export default classAttend

export default [
  {
    path: '/classroom',
    name: 'classroom',
    component: () => import('@/themes/classroom/index'),
    meta: {
      title: '云上课堂',
      pushPage: true
    },
    children:[
      {
        path:'list',
        name:'list',
        component:()=> import('@/themes/classroom/list'),
        meta: {
          title: '云上课堂',
          //keepAlive: true
        }
      },
      {
        path:'info',
        name:'info',
        component:()=> import('@/themes/classroom/info'),
        meta: {
          title: '课程详情',
          //keepAlive: false
        }
      }
    ]
  }
]

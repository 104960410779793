export default [
  {
    path: '/city',
    name: 'city',
    component: () => import('@/themes/city/index'),
    meta: {
      title: '市指挥中心',
      pushPage: true
    }
  },
  {
    path: '/dev',
    name: 'dev',
    component: () => import('@/themes/city/dev'),
    meta: {
      title: '市指挥中心',
      pushPage: true
    }
  },
]

import axios from './axios'
import qs from 'qs'
import {apiUrl} from '@/config/config'
// 获取教委基础设置  /district-command-center/settings/getEduBaseSettins
export const getBaseSet = (data) => {
    return new Promise((resolve,reject) => {
        axios({
            url: apiUrl + '/district-command-center/settings/getEduBaseSettins',
            method: 'get',
            params: data
        }).then(res => {
            resolve(res.data)
        })
    })
}
// 获取模块列表  /district-command-center/settings/getTemplate
export const getTemplate = (data) => {
    return new Promise((resolve,reject) => {
        axios({
            url: apiUrl + '/district-command-center/settings/getTemplate',
            method: 'post',
            data
        }).then(res => {
            resolve(res.data)
        })
    })
}
// 刷新首页设置  /district-command-center/settings/updateHomepageSettings
export const updateHomepageSettings = (data) => {
    return new Promise((resolve,reject) => {
        axios({
            url: apiUrl + '/district-command-center/settings/updateHomepageSettings',
            method: 'post',
            data
        }).then(res => {
            resolve(res.data)
        })
    })
}
import axios from './axios'
import qs from 'qs'
import {apiUrl} from '@/config/config'
// 宿舍管理数据  /district-command-center/dataStatistics/dormData/{eduId}
export const getData = (data) => {
    return new Promise((resolve,reject) => {
        axios({
            url: apiUrl + '/district-command-center/dataStatistics/dormData',
            method: 'get',
            params: data
        }).then(res => {
            resolve(res.data)
        })
    })
}
// 宿舍管理 今日数据  /district-command-center/dataStatistics/dormDataDetail/{eduId}
export const getTodayData = (data) => {
    return new Promise((resolve,reject) => {
        axios({
            url: apiUrl + '/district-command-center/dataStatistics/dormDataDetail',
            method: 'get',
            params: data
        }).then(res => {
            resolve(res.data)
        })
    })
}
import {refreshToken} from '@/api/commonApi'
import {clientId, clientSecret, env} from '@/config/config'
import cookie from '@/util/cookie'
export default {
    time: 3600000,
    async refreshToken () {
        var authToken = cookie.get('authToken') || localStorage.getItem('authToken')
        var params = {             
            refresh_Token: (JSON.parse(authToken)).refresh_token,
            clientId: clientId,
            clientSecret: clientSecret
        }
        var res = await refreshToken(params)
        const token = decodeURIComponent(JSON.stringify(res));
        cookie.set('authToken', token, 100)	
        localStorage.setItem('authToken', token);
    },

    refreshLoop () {
        setTimeout(async () => {
            await this.refreshToken()
            this.refreshLoop()
        }, this.time)
    }
}
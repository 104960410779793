const obj = {
    install (Vue) {
        function changeColor(el, binding){
            var value = parseFloat(binding.value + '')
                
               if(value < 36 || 37.3 < value ){
                   el.style.color = 'red'
               } else {
                el.style.color = ''
               }
        }



        Vue.directive('temperature', {

            
            
            bind (el, binding, vnode) {

            },
            inserted (el, binding, vnode) {
                changeColor(el, binding)
            },
            update (el, binding) { 
                changeColor(el, binding)
            },
            componentUpdated (el, binding) {
            },
            unbind (el, binding) {

            }
        })
    }
}
export default obj
const focus = {
    install (Vue) {

        Vue.directive('focus', {
            inserted: function (el, {value}) {
                if (value) {
                    el.focus()
                }
            }
        })


        Vue.directive('loadmore', {
            bind(el, binding) {
                var p = 0;
                var t = 0;
                var down = true;
                var interval = 300
                var isActive = true

                el.addEventListener('scroll', function() {
                    if (!isActive) {
                        return
                    }
                    //判断是否向下滚动
                    p = this.scrollTop;
                    if(t < p){
                        down = true;
                    }else{
                        down = false;
                    }
                    t = p;
                    //判断是否到底
                    const sign = 10;
                    const scrollDistance = this.scrollHeight - this.scrollTop - this.clientHeight;
                    if (scrollDistance <= sign && down) {
                        binding.value()
                        isActive = false
                        setTimeout(() => {
                            isActive = true
                        }, interval)
                    }
                });
            }
        })

    }
}
export default focus


const county = {
    namespaced: true,
    state: {
      indexModelShow:false,      //首页
      partyModalShow:false,      //云上党建
      classModalShow:false,      //课堂考勤
      schoolModalShow:false,     //实时在校数据
      temperatureModalShow:false,//体温监测
      dormModalShow:false,       //宿舍管理
      indexSetShow: false,       //首页设置
      alarmModalShow:false,       //校园报警
      warningSetShow: false,      //预警设置
      defenceModalShow: false,    //健康监测
      dangerModelShow: false, //危险区域
      centerMap: null,
      callPolice: null,
      schoolData: '',
      defenceModalTabIndex: 0
    },
    getters: {
        
    },
    mutations: {
      
    },
    actions: {
     
    }
}
export default county

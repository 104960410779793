import axios from 'axios'
import store from '@/store'
import router from '../router'
import cookie from '@/util/cookie'
import Vue from 'vue'
import {Encrypt} from '@/util/crypto'
import {wwwUrl} from '@/config/config'
import {refreshToken} from '@/api/commonApi'
import {clientId, clientSecret, env} from '@/config/config'
axios.defaults.timeout = 30000
axios.defaults.withCredentials = false // 跨域请求，允许保存cookie


// 添加请求拦截器
var loading;
axios.interceptors.request.use(function (config) {
	config.headers['x-fuyu-url'] = location.origin + location.pathname
	// 签名
config.headers['sign'] = Encrypt(new Date().getTime())
// config.headers['schoolId'] = cookie.get("schoolId");
	// loading = Vue.prototype.$loading({
	// 	lock: false,
	// 	text: '加载中...',
	// 	spinner: 'el-icon-loading',

	// 	background: 'rgba(0, 0, 0, 0.7)'
	//   });


	// config.headers['roleCode'] =cookie.get("roleCode")
	// config.headers['Token'] = cookie.get("Token")
	config.headers['X-User-Name'] = cookie.get("userName")

	var authToken = cookie.get('authToken') || localStorage.getItem('authToken')

	if (authToken &&  authToken != 'undefined') {

		config.headers['Authorization'] = 'Bearer ' + (JSON.parse(authToken)).access_token
	} else {

	}


	return config
}, function (error) {
	console.log('error' + error)
	return Promise.reject(error)
})
// 添加响应拦截器
axios.interceptors.response.use(function (response) {
	//loading.close();
	return response.data
}, function (error) {
	switch(error.response.status){
		case 302:

			Vue.prototype.$alert('登录过期，请重新登陆(302)', '提示', {
				confirmButtonText: '确定',
				callback: action => {
					cookie.clearAll()
					location.href = wwwUrl + '#/login'
				}
			  })
		break
		case 401:
			var authToken = cookie.get('authToken') || localStorage.getItem('authToken')
			if(!authToken){ //
				location.href = wwwUrl + '#/login'
				return
			}
			var params = {
				refresh_Token: (JSON.parse(authToken)).refresh_token,
				clientId: clientId,
				clientSecret: clientSecret
			}
			return refreshToken(params).then(res => {
				  const token = decodeURIComponent(JSON.stringify(res));
					cookie.set('authToken', token, 100)
					localStorage.setItem('authToken', token);

					var config = error.config
					config.headers['Authorization'] = 'Bearer ' + res.access_token
					return axios(config)
			})
		break
		case 403:
			var data = error.response.data.error
			if (data.code + '' == 302 + '') {
				Vue.prototype.$alert(data.message + '(403302)', '提示', {
					confirmButtonText: '确定',
					callback: action => {
						cookie.clearAll()
						location.href = wwwUrl + '#/login'
					}
				})
				return
			}

			Vue.prototype.$message.error(data.message)
		break
		case 500:
			Vue.prototype.$message.error('状态：500,服务器出错了')
		break
	}
	console.log(error)
	return Promise.reject()
})
export default axios

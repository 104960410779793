import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import user from './modules/user'
import county from './modules/county'
import cloudParty from './modules/cloudParty'
import dormManage from './modules/dormManage'
import classAttend from './modules/classAttend'
import indexSet from './modules/indexSet'
Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {
	user,
  county,
  cloudParty,
  dormManage,
  classAttend,
  indexSet
  },  
  getters,

  actions:{
   
  }
})
export default store

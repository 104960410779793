import cookie from '@/util/cookie'
import refreshToken from '@/util/refreshToken'

function getCountyUserInfo(){
    
    var search = location.search
    
    search = decodeURIComponent(search)
    search = search.replace('?', '')
    var arr = search.split('&')
    var obj = {}
    for (var item of arr) {
        var list = item.split('=')
        obj[list[0]] = list[1]
    }
   
    if(obj.countyUserInfo){
        return JSON.parse(obj.countyUserInfo)
    }

    if(localStorage.countyUserInfo){
        return JSON.parse(localStorage.countyUserInfo) 
    }

    return ''
   
}

const user = {
    namespaced: true,
    state: {
       userInfo: localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {},
       countyUserInfo: getCountyUserInfo(),
    },
    getters: {
      isClient () {
         if (typeof(QCefClient) != 'undefined') {
             return true
         } else {
             return false
         }
     }
    },
    mutations: {
       setUserInfo (state, {info, redirectUrl}) {
           if(!redirectUrl){
                redirectUrl = ''
           }
           if (redirectUrl.indexOf('#/city') != -1) {
            state.userInfo = info
            localStorage.userInfo = JSON.stringify(info)
           }

           if (redirectUrl.indexOf('#/county') != -1) {
            state.countyUserInfo = info
            localStorage.countyUserInfo = JSON.stringify(info)
           }
           const userInfo = JSON.stringify(info);
           cookie.set('userInfo', userInfo, 100)
           cookie.set('userName', info.userName, 100)
           localStorage.setItem('userName', info.userName);
           localStorage.setItem('userInfo', userInfo);
       },
       setCountyUserInfo (state, info) {
        // name: "路奇"
        // organizationId: "39fa5519-dbd8-4e36-7b37-92d9b634a579"
        // organizationIdStr: "39fa5519dbd84e367b3792d9b634a579"
        // organizationName: "河北省石家庄市教育局"
        // phoneNumber: "13400000001"
        // userName: "13400000001"

        // areaId: "130127"
        // areaName: "高邑县"
        // eduId: "39fbfa2fa858fa0b2c8d1a18d12f06e4"
        // eduName: "河北省石家庄市高邑县教育局"

        var obj = {
            organizationIdStr: info.eduId,
            organizationName: info.eduName
        }
        state.countyUserInfo = obj
        localStorage.countyUserInfo = JSON.stringify(obj)

       },
       setToken (state, token) {
          const tokenStringify = JSON.stringify(token);
          cookie.set('authToken', tokenStringify, 1)
          localStorage.setItem('authToken', tokenStringify);
          state.token = token
       }
    },
    actions: {

    }
}
export default user

import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import county from './modules/county'
import city from './modules/city'
import classroom from './modules/classroom'
import monitorCenter from './modules/monitorCenter'
import videoConferencing from './modules/videoConferencing'
Vue.use(Router)
// 路由实例
const router = new Router({
  scrollBehavior (to, from, savedPosition) { // 路由滚动行为
    if (savedPosition) {
        return savedPosition
    } else {
        const position = {}
        if (to.hash) {
            position.selector = to.hash
        }
        if (to.matched.some(m => m.meta.scrollToTop)) {
            position.x = 0
            position.y = 0
        }
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(position)
            }, 500)
        })
    }
  },
  routes: [
    ...county,
    ...city,
    ...classroom,
    ...monitorCenter,
    ...videoConferencing,
    {
      path: '/',
      redirect: '/start'
    },
    {
      path: '/test',
      name: 'test',
      meta: {
        pushPage: true
      },
      component: () => import(/* webpackChunkName: "group-foo" */'../themes/test/test')
    },
    {
      path: '/zengTest',
      name: 'zengTest',
      meta: {
        pushPage: true,
        title: '曾测试'
      },
      component: () => import(/* webpackChunkName: "group-foo" */'../themes/zengTest/index')
    },
    {
      path: '/login',
      name: 'Login',
      meta: {
        pushPage: true,
        title: '登录'
      },
      component: () => import(/* webpackChunkName: "group-foo" */'../themes/login/login')
    },
    {
      path: '*',
      redirect: '/error/404'
    },
    {
      path: '/error/403',
      name: 'Error403',
      component: () => import(/* webpackChunkName: "group-foo" */'../themes/error/403')
    },
    {
      path: '/error/404',
      name: 'Error404',
      component: () => import(/* webpackChunkName: "group-foo" */'../themes/error/404')
    }
  ]
})
export default router


// 路由生命周期
router.beforeEach((to, from, next) => {

  next()
})
// 路由生命周期
router.afterEach((to, from) => {
  // console.log(to)
  window.document.title = to.meta.title

  document.body.scrollTop=document.documentElement.scrollTop=0
})


let a = ''
let id = ''
let api = ''
let socket = ''
let www = ''
let edu = ''
let busi = ''
let cloud = ''
let card = ''
let app = ''

let host = location.host

switch (host) {
    case 'luomi.cn':
        a = 'https://luomi.cn'
        api = 'https://api.xueerqin.net'
        id = 'fuyu_luomi_SC'
        socket = 'wss://websocket.xueerqin.net'
        www = 'https://luomi.cn/dash'
        edu = 'https://luomi.cn/edu'
        busi = 'https://busi.xueerqin.net'
        cloud = 'https://cloudcourse.xueerqin.net'
        card = 'https://luomi.cn/cardapp'
        app = 'https://apps.xueerqin.net'
        break

    case 'stag.luomi.cn':
        a = 'https://stag.luomi.cn'
        api = 'https://s-api.xueerqin.net'
        id = 'fuyu_luomi_SC'
        socket = 'wss://s-websocket.xueerqin.net'
        www = 'https://stag.luomi.cn/dash'
        edu = 'https://stag.luomi.cn/edu'
        busi = 'https://s-busi.xueerqin.net'
        cloud = 'https://s-cloudcourse.xueerqin.net'
        card = 'https://stag.luomi.cn/cardapp'
        app = 'https://sapp.xueerqin.net'   
        break
    default:
        a = 'https://test.luomi.cn'
        api = 'https://t-api.xueerqin.net'
        // api = 'http://192.168.66.42:9668'
        id = 'fuyu_luomi_SC'
        socket = 'wss://t-websocket.xueerqin.net'
        www = 'https://test.luomi.cn/dash'
        edu = 'https://test.luomi.cn/edu'
        busi = 'https://t-busi.xueerqin.net'
        cloud = 'https://t-cloudcourse.xueerqin.net'
        card = 'https://test.luomi.cn/cardapp'
        app = 'https://tapp.xueerqin.net'
}

export const oauthUrl = a
export const eduUrl = edu
export const wwwUrl = www
export const busiUrl = busi
export const cloudUrl = cloud
export const clientId = 'fuyu_luomi'
export const clientSecret = 'fjfy1q2w3E*'
export const cardUrl = card
export const appUrl = app

export const apiUrl = api
// export const apiUrl = 'http://192.168.66.42:9668'
export const socketUrl = socket

export const env = process.env.NODE_ENV

export const refreshTime = 60000




